import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { useHistory } from 'react-router'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import { ColorModeContext } from './theme-context'
import { useTheme } from '@mui/material/styles'
import { useCookies } from 'react-cookie'
import { firebaseConnect, isLoaded } from 'react-redux-firebase'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SimpleBottomNavigation from './SimpleBottomNavigation'
import { lajit, routes } from './config'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withAppconfig from './AppContext'
import { useMediaQuery } from '@mui/material'

const classes = {
  title: {
    lineHeight: '30px',
    fontSize: '18px',
    borderRadius: '3px',
    textTransform: 'none',
    color: 'inherit',
    padding: '8px 16px',
    letterSpacing: 'unset',
    '&:hover,&:focus': {
      color: 'inherit',
      background: 'transparent',
    },
  },
}

const ThemeButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))

function Navigation({ leagues, dispatch, appconfig, league }) {
  const history = useHistory()
  const theme = useTheme()
  // const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorElLeague, setAnchorElLeague] = React.useState(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [selectedLeague, setSelectedLeague] = React.useState(league)
  // const openLaji = Boolean(anchorEl)
  const openLeague = Boolean(anchorElLeague)
  const colorMode = React.useContext(ColorModeContext)
  const [cookies, setCookie] = useCookies(['sport', 'leagueid', 'mode', 'favoriteteam'])
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  const brandComponent = (
    <ThemeButton  sx={{ display: { xs: 'none', sm: 'block' } }} className={classes.title} onClick={() => history.push('/')}>
      {appconfig.brand.text}
    </ThemeButton>
  )

  if (cookies.sport) {
    const index = lajit.findIndex((x) => x === cookies.sport)
    if (selectedIndex !== index) {
      setSelectedIndex(index)
    }
  } else {
    if (selectedIndex === null) {
      setSelectedIndex(0)
    }
  }

  if (cookies.leagueid) {
    // console.log('league cookie found', cookies.leagueid)
    if (leagues && leagues.length > 0) {
      // console.log('leagues', leagues)
      const league = leagues.find((x) => x.key === cookies.leagueid)
      if (league) {
        // console.log('league', league, 'selectedLeague', selectedLeague, 'leagues', leagues)
        if (!selectedLeague || selectedLeague.key !== league.key) {
          setSelectedLeague(league)
        }
      }
    }
  } else {
    // console.log('cookie', selectedLeague)
    if (selectedLeague !== null) {
      setSelectedLeague(null)
    }
  }

  // const handleSportClick = (event, index) => {
  //   // console.log('handleSportClick', event.currentTarget, index)
  //   setAnchorEl(event.currentTarget)
  // }

  const handleLeagueClick = (event, index) => {
    // console.log('handleLeagueClick', event.currentTarget, index)
    setAnchorElLeague(event.currentTarget)
  }

  const toggleDrawer = (open) => {
    setDrawerOpen(open)
  }


  const handleLeagueClose = () => {
    setAnchorElLeague(null)
  }


  const handleLeagueMenuItemClick = (event, leagueid) => {
    const league = leagues.find((x) => x.key === leagueid)
    if (league) {
      setSelectedLeague(league)
      setCookie('leagueid', leagueid, { path: '/', maxAge: 60000, secure: true })
      setAnchorElLeague(null)
      toggleDrawer(false)
      dispatch({
        type: 'CLEAR_GENDER'
      })
      dispatch({
        type: 'CLEAR_GROUP'
      })
      dispatch({
        type: 'CLEAR_DIVISION'
      })
    }
  }

  const toggleMode = () => {
    const mode = theme.palette.mode === 'dark' ? 'light' : 'dark'
    setCookie('mode', mode, { path: '/', maxAge: 60000, secure: true })
    colorMode.toggleColorMode()
  }

  React.useEffect(() => {
    // console.log('useEffect 0', league)
    if (league) {
      setSelectedLeague(league)
      setCookie('leagueid', league.key, { path: '/', maxAge: 60000, secure: true })
    }
  }, [setSelectedLeague, setCookie, league])

  React.useEffect(() => {
    // console.log('useEffect 1', selectedIndex)
    if (selectedIndex > -1) {
      // console.log('update sport', selectedIndex)
      dispatch({
        type: 'UPDATE_SPORT',
        payload: lajit[selectedIndex],
      })
    }
  }, [selectedIndex, dispatch])

  React.useEffect(() => {
    if (selectedLeague) {
      dispatch({
        type: 'UPDATE_LEAGUE',
        payload: selectedLeague,
      })
      dispatch({
        type: 'UPDATE_SPORT',
        payload: selectedLeague.value.sport.toUpperCase(),
      })
      const index = lajit.findIndex((x) => x === selectedLeague.value.sport.toUpperCase())
      setSelectedIndex(index)
      setCookie('sport', lajit[index], { path: '/', maxAge: 60000, secure: true })
    }
  }, [selectedLeague, dispatch, setSelectedIndex, setCookie])


  if (isLoaded(leagues) && leagues.length > 0) {
    // console.log('render navigation', leagues, selectedIndex, selectedLeague)
    return (
      <React.Fragment>
        <Box sx={{ flexGrow: 1, pt: { xs: 6, md: 0 } }}>
          <AppBar position={isSmallScreen ? 'fixed' : 'static'} color='primary' enableColorOnDark>
            <Toolbar
              sx={{
                justifyContent: 'space-between',
                // backgroundImage: {
                //   sm: "url('https://images.unsplash.com/photo-1551782450-a2132b4ba21d?w=164&h=164&fit=crop&auto=format')",
                //   xs: "none" },
                // backgroundRepeat: { sm: "no-repeat", xs: 'none' },
                // backgroundSize: { sm: "cover", xs: "none" }
              }}
            >
              {brandComponent}
              <ThemeButton
                id='league-positioned-button'
                aria-controls='league-positioned-menu'
                aria-haspopup='true'
                aria-expanded={openLeague ? 'true' : undefined}
                onClick={(event) => { isSmallScreen ? toggleDrawer(true) : handleLeagueClick(event) }}
                endIcon={<ExpandMoreIcon />}
              >
                {selectedLeague ? selectedLeague.value.name : 'Valitse sarja'}
              </ThemeButton>
              {!isSmallScreen ?
              <Menu
                id='league-positioned-menu'
                aria-labelledby='league-positioned-button'
                anchorEl={anchorElLeague}
                open={openLeague}
                onClose={handleLeagueClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                {leagues.map((option, index) => (
                  <MenuItem
                    key={option.key}
                    selected={selectedLeague ? option.key === selectedLeague.key : false}
                    onClick={(event) => handleLeagueMenuItemClick(event, option.key)}
                  >
                    {option.value.name}
                  </MenuItem>
                ))}
              </Menu>
              : null}
              {/* <ThemeButton
                id='demo-positioned-button'
                aria-controls='demo-positioned-menu'
                aria-haspopup='true'
                aria-expanded={openLaji ? 'true' : undefined}
                // onClick={handleSportClick}
                // endIcon={<ExpandMoreIcon />}
              >
                {lajit[selectedIndex]}
              </ThemeButton> */}
              {/* <Menu
                id='demo-positioned-menu'
                aria-labelledby='demo-positioned-button'
                anchorEl={anchorEl}
                open={openLaji}
                onClose={handleSportClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                {lajit.map((option, index) => (
                  <MenuItem
                    key={option}
                    selected={index === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, index)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu> */}
              {routes.map((r, i) => {
                return (
                  <ThemeButton
                    key={r.name}
                    onClick={() => history.push(r.path)}
                    variant='text'
                    sx={{ display: { sm: 'block', xs: 'none' } }}
                  >
                    {r.name}
                  </ThemeButton>
                )
              })}
              <ThemeButton
                onClick={toggleMode}
                startIcon={theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                color={theme.palette.mode === 'dark' ? 'primary' : 'inherit'}
              >
                {/* <IconButton
            sx={{ display: { sm: 'block', xs: 'none' } }}
            onClick={toggleMode}
            color={theme.palette.mode === 'dark' ? 'primary' : 'inherit'}
          >
            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton> */}
              </ThemeButton>
              {/* <Menu
            id='smallmenu'
            aria-labelledby='smallmenu'
            anchorEl={anchorElSmall}
            open={openSmall}
            onClose={handleCloseSmall}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {routes.map((r, i) => {
              return (
                <MenuItem key={r.name} onClick={() => history.push(r.path)}>
                  {r.name}
                </MenuItem>
              )
            })}
            <MenuItem onClick={toggleMode}>
              <IconButton
                sx={{ ml: 1 }}
                // onClick={toggleMode}
                color={theme.palette.mode === 'dark' ? 'primary' : 'inherit'}
              >
                {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
              {theme.palette.mode === 'dark' ? 'Light' : 'Dark'}
            </MenuItem>
          </Menu>
          <IconButton
            size='large'
            edge='start'
            color={theme.palette.mode === 'dark' ? 'primary' : 'inherit'}
            aria-label='menu'
            sx={{ mr: 2, display: { sm: 'none', xs: 'block' } }}
            onClick={handleSmallClick}
          >
            <MenuIcon />
          </IconButton> */}
            </Toolbar>
          </AppBar>
          {/* {isSmallScreen ? <Toolbar /> : null} */}
        </Box>
        <Drawer sx={{ zIndex: 10000 }} anchor={'bottom'} open={drawerOpen} onClose={() => toggleDrawer(false)}>
          <List>
            {leagues.map((option, index) => (
              <ListItem
                key={option.key}
                onClick={(event) => handleLeagueMenuItemClick(event, option.key)}
              >
                <ListItemText primary={option.value.name} />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <SimpleBottomNavigation />
      </React.Fragment>
    )
  } else {
    return <></>
  }
}

const withLeagues = compose(
  firebaseConnect((props) => [
    {
      path: 'customers/' + props.appconfig.customerid + '/configs/leagues',
    },
  ]),
  connect((state, props) => {
    if (
      state.firebase.ordered.customers &&
      state.firebase.ordered.customers[props.appconfig.customerid] &&
      state.firebase.ordered.customers[props.appconfig.customerid].configs
    ) {
      return {
        leagues: state.firebase.ordered.customers[props.appconfig.customerid].configs.leagues.filter((x) => x.value.state === 'published'),
        dispatch: props.firebase.dispatch,
        league: state.sport?.league
      }
    } else {
      return {
        leagues: [],
        dispatch: props.firebase.dispatch,
        league: state.sport?.league
      }
    }
  })
)

export default withAppconfig(withLeagues(Navigation))
