import * as React from 'react'
// import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import Navigation from './Navigation'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { connect } from 'react-redux'
import { firebaseConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import DatePicker from '@mui/lab/DatePicker'
import TextField from '@mui/material/TextField'
import { format, parseISO, isSameDay, isAfter, parse } from 'date-fns'
import { fi } from 'date-fns/locale'
import { getTieScores } from './Scores'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import fiLocale from 'date-fns/locale/fi'
import { genders, divisions } from './config'
import { Button, Chip, ButtonGroup } from '@mui/material'
import { TeamColors } from '@cintoiacom/team-colors'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import { filterLeagueFixtures, filterGroup, filterLeagueTeam } from './StateFilters'
import { CircularProgress } from '@mui/material'
import withAppconfig from './AppContext'
import { useMediaQuery } from '@mui/material'
// import FaceIcon from '@mui/icons-material/Face'
import AssignmentIcon from '@mui/icons-material/Assignment'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
// import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import MuiMarkdown from 'mui-markdown'
// import { FacebookProvider, Page } from 'react-facebook'
import { renderRubberTable } from './renderUtils'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

// const Item = styled(Box)(({ theme }) => ({
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }))

const TieContainer = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  // padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}))

const sortByEntryend = (a, b) => {
  const ae = parse(a.value.entryend, 'd.M.yyyy', new Date())
  const be = parse(b.value.entryend, 'd.M.yyyy', new Date())
  if (isAfter(ae, be)) {
    return -1
  } else if (isAfter(be, ae)) {
    return 1
  } else {
    return 0
  }
}

function FixturesPage({ auth, dispatch, teams, fixtures, locations, nextDay, matchDates, league, leagues, appconfig }) {
  const [selectedDate, setSelectedDate] = React.useState(format(nextDay, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"))
  const [matchDateIndex, setMatchDateIndex] = React.useState(0)
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const [rulesOpen, setRulesOpen] = React.useState(false)

  React.useEffect(() => {
    if (nextDay) {
      const nextDateString = format(nextDay, 'yyyy-MM-dd')
      setMatchDateIndex(
        Math.max(
          0,
          matchDates.findIndex((x) => x === nextDateString)
        )
      )
      setSelectedDate(format(nextDay, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"))
    }
  }, [nextDay, matchDates])

  const handleRulesOpen = () => {
    setRulesOpen(true)
  }

  const handleRulesClose = () => {
    setRulesOpen(false)
  }

  const handleLeagueMenuItemClick = (event, leagueid) => {
    const league = leagues.find((x) => x.key === leagueid)
    if (league) {
      dispatch({
        type: 'UPDATE_LEAGUE',
        payload: league,
      })
    }
  }

  const renderDateTies = () => {
    return fixtures
      .filter((x) => isSameDay(new Date(selectedDate), parseISO(x.value.time)))
      .map((f) => {
        if (isSmallScreen) {
          return renderSmallScreenTie(f)
        } else {
          return renderTie(f)
        }
      })
  }

  const onMatchDate = (direction) => {
    let newIndex = matchDateIndex + direction
    // console.log(newIndex)
    newIndex = Math.max(0, newIndex)
    newIndex = Math.min(matchDates.length - 1, newIndex)
    // console.log(newIndex)
    setSelectedDate(format(parse(matchDates[newIndex], 'yyyy-MM-dd', new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"))
    setMatchDateIndex(newIndex)
  }

  const renderSmallScreenTie = (tie) => {
    const location = tie.value.location ? locations.find((l) => l.key === tie.value.location) : null
    let score = null
    if (tie.value.rubbers && tie.value.rubbers.length > 0 && tie.value.rubbers[0].score) {
      const { homepoints, awaypoints } = getTieScores(tie.value.rubbers, tie.value.sport)
      score = (
        <>
          <Grid item xs={5} sx={{ textAlign: 'right' }}>
            <Typography ml={1} mr={1} color={'lightgreen'} variant='subtitle1' component='span'>
              {homepoints}
            </Typography>
          </Grid>
          <Grid item xs={1} sx={{ textAlign: 'center' }}>
            {' - '}
          </Grid>
          <Grid item xs={5} sx={{ textAlign: 'left' }}>
            <Typography ml={1} mr={1} color={'lightgreen'} variant='subtitle1' component='span'>
              {awaypoints}
            </Typography>
          </Grid>
        </>
      )
    }
    const hometeam = teams.find((t) => t.key === tie.value.home.id)
    const awayteam = teams.find((t) => t.key === tie.value.away.id)
    return (
      <TieContainer mb={1} key={tie.key}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
            <Grid container spacing={0}>
              <Grid item xs={12} sx={{ textAlign: 'center', mb: 1 }}>
                <Typography variant='caption' component='span'>
                  {genders.find((x) => x.key === tie.value.gender).title} -{' '}
                  {divisions.find((x) => x.key === tie.value.division).title}{' '}
                  {tie.value.group ? ' - lohko ' + tie.value.group : ''}
                </Typography>
              </Grid>
              <Grid item xs={5} sx={{ textAlign: 'right' }}>
                <Typography variant='subtitle2' component='span' sx={{ mr: 1 }}>
                  {tie.value.home.name}
                </Typography>
                <TeamColors
                  width={12}
                  height={12}
                  color1={hometeam?.value?.logo?.color1 || 0}
                  color2={hometeam?.value?.logo?.color2 || 0}
                  color3={hometeam?.value?.logo?.color3 || 0}
                  shape={hometeam?.value?.logo?.shape || 1}
                  template={hometeam?.value?.logo?.template || 0}
                />
              </Grid>
              <Grid item xs={1} sx={{ textAlign: 'center' }}>
                <Typography variant='subtitle2' component='span'>
                  {' - '}
                </Typography>
              </Grid>
              <Grid item xs={5} sx={{ textAlign: 'left' }}>
                <TeamColors
                  width={12}
                  height={12}
                  color1={awayteam?.value?.logo?.color1 || 0}
                  color2={awayteam?.value?.logo?.color2 || 0}
                  color3={awayteam?.value?.logo?.color3 || 0}
                  shape={awayteam?.value?.logo?.shape || 1}
                  template={awayteam?.value?.logo?.template || 0}
                />
                <Typography variant='subtitle2' component='span' sx={{ ml: 1 }}>
                  {tie.value.away.name}
                </Typography>
              </Grid>
              {!score ? (
                <>
                  <Grid item xs={4} sm={3} sx={{ mt: 1 }}>
                    <Typography variant='body2' component='div'>
                      {tie.value.time
                        ? format(parseISO(tie.value.time), 'eee d.M.yyyy p', {
                            locale: fi,
                          })
                        : null}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={3} sx={{ mt: 1 }}>
                    <Typography variant='body2' component='div'>
                      {location ? location.value.name : null} - Kierros {tie.value.round + 1}
                    </Typography>
                  </Grid>
                </>
              ) : (
                // <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <>{score}</>
                // </Grid>
              )}
              {tie.value.note ? (
                <Grid item>
                  <Chip label={tie.value.note} size='small' />
                </Grid>
              ) : null}
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item>
              {score ? (
                <Typography variant='body2' component='div' sx={{ mb: 1 }}>
                  Kierros {tie.value.round + 1}
                </Typography>
              ) : null}
              {tie.value.rubbers
                .sort((a, b) => {
                  if (a.time < b.time) { return -1 }
                  if (a.time > b.time) { return 1 }
                  return 0
                }).map((r) => renderRubberTable(r, hometeam, awayteam))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </TieContainer>
    )
  }

  const renderTie = (tie) => {
    // console.log('tie', tie)
    const location = tie.value.location ? locations.find((l) => l.key === tie.value.location) : null
    let score = null
    if (tie.value.rubbers && tie.value.rubbers.length > 0 && tie.value.rubbers[0].score) {
      const { homepoints, awaypoints } = getTieScores(tie.value.rubbers, tie.value.sport)
      score = (
        <Typography ml={1} mr={1} color={'lightgreen'} variant='subtitle1' component='span'>
          {homepoints} - {awaypoints}
        </Typography>
      )
    }
    const hometeam = teams.find((t) => t.key === tie.value.home.id)
    const awayteam = teams.find((t) => t.key === tie.value.away.id)
    // console.log('score', score)
    return (
      <TieContainer mb={1} key={tie.key}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
            <Grid container spacing={0}>
              <Grid item xs={12} sx={{ textAlign: 'center', mb: 1 }}>
                <Typography variant='caption' component='span'>
                  {genders.find((x) => x.key === tie.value.gender).title} -{' '}
                  {divisions.find((x) => x.key === tie.value.division).title}{' '}
                  {tie.value.group ? ' - lohko ' + tie.value.group : ''}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={score ? 12 : 6}>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <Typography variant='subtitle2' component='span'>
                    {tie.value.home.name}
                  </Typography>
                  <TeamColors
                    width={12}
                    height={12}
                    color1={hometeam?.value?.logo?.color1 || 0}
                    color2={hometeam?.value?.logo?.color2 || 0}
                    color3={hometeam?.value?.logo?.color3 || 0}
                    shape={hometeam?.value?.logo?.shape || 1}
                    template={hometeam?.value?.logo?.template || 0}
                  />
                  <Typography variant='subtitle2' component='span'>
                    {' - '}
                  </Typography>
                  <TeamColors
                    width={12}
                    height={12}
                    color1={awayteam?.value?.logo?.color1 || 0}
                    color2={awayteam?.value?.logo?.color2 || 0}
                    color3={awayteam?.value?.logo?.color3 || 0}
                    shape={awayteam?.value?.logo?.shape || 1}
                    template={awayteam?.value?.logo?.template || 0}
                  />
                  <Typography variant='subtitle2' component='span'>
                    {tie.value.away.name} {score}
                  </Typography>
                </Stack>
              </Grid>
              {!score ? (
                <>
                  <Grid item xs={4} sm={3}>
                    <Typography variant='body2' component='div'>
                      {tie.value.time
                        ? format(parseISO(tie.value.time), 'eee d.M.yyyy p', {
                            locale: fi,
                          })
                        : null}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={3}>
                    <Typography variant='body2' component='div'>
                      {location ? location.value.name : null} - Kierros {tie.value.round + 1}
                    </Typography>
                  </Grid>
                </>
              ) : null}
              {tie.value.note ? (
                <Grid item>
                  <Chip label={tie.value.note} size='small' />
                </Grid>
              ) : null}
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item>
              {score ? (
                <Typography variant='body2' component='div'>
                  Kierros {tie.value.round + 1}
                </Typography>
              ) : null}
              {tie.value.rubbers
                ? tie.value.rubbers
                  .sort((a, b) => {
                    if (a.time < b.time) { return -1 }
                    if (a.time > b.time) { return 1 }
                    return 0
                  })
                    .map((r) => renderRubberTable(r, hometeam, awayteam))
                : null}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </TieContainer>
    )
  }

  const isLeague = !(typeof league === 'undefined' || league === null)

  if (!isLeague) {
    return (
      <Box
        sx={{
          width: '100vw',
          minHeight: '100vh',
          height: '100%',
          bgcolor: 'background.default',
          pb: 10,
          paddingLeft: 'env(safe-area-inset-left)',
          paddingRight: 'env(safe-area-inset-right)',
        }}
      >
        <Container
          sx={{
            bgcolor: 'background.default',
            color: 'text.primary',
          }}
        >
          <Navigation />
          <Grid container spacing={2} sx={{ textAlign: 'center', mt: 2 }}>
            <Grid item xs={12}>
              <Typography>Sarjat</Typography>
              <ButtonGroup orientation='vertical' aria-label='vertical contained button group' variant='contained'>
                {leagues.sort(sortByEntryend).map((option) => (
                  <Button key={option.key} onClick={(event) => handleLeagueMenuItemClick(event, option.key)}>
                    {option.value.sport + ' - ' + option.value.name}
                  </Button>
                ))}
              </ButtonGroup>
            </Grid>
          </Grid>
        </Container>
      </Box>
    )
  } else if (isLoaded(teams)) {
    // console.log('teams', teams)
    return (
      <Box
        sx={{
          width: '100vw',
          minHeight: '100vh',
          height: '100%',
          bgcolor: 'background.default',
          pb: 10,
        }}
      >
        <Container
          sx={{
            bgcolor: 'background.default',
            color: 'text.primary',
          }}
        >
          <Navigation />
          <Grid container spacing={2} sx={{ mb: 2, mt: 1 }}>
            <Grid item xs={12} md={7} sx={{ textAlign: { xs: 'center', md: 'right' } }}>
              {/* <Item> */}
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={fiLocale}>
                <DatePicker
                  label='Pelipäivä'
                  value={selectedDate || new Date()}
                  onChange={(newValue) => {
                    setSelectedDate(newValue)
                  }}
                  disableOpenPicker={true}
                  readOnly={true}
                  renderInput={(params) => (
                    <>
                      <Button onClick={() => onMatchDate(-1)}>
                        <ArrowLeftIcon fontSize='large' />
                      </Button>
                      <TextField {...params} sx={{ maxWidth: 110 }} />
                      <Button onClick={() => onMatchDate(1)}>
                        <ArrowRightIcon fontSize='large' />
                      </Button>
                    </>
                  )}
                />
              </LocalizationProvider>
              {/* </Item> */}
            </Grid>
            {/* </Grid>
          <Grid container> */}
            <Grid item xs={12} md={2} sx={{ mb: 1, textAlign: 'center' }}>
              <Chip sx={{ cursor: 'pointer' }} icon={<AssignmentIcon />} label='Säännöt' onClick={handleRulesOpen} />
            </Grid>
          </Grid>
          <Grid container spacing={5} justifyContent='center' alignItems='center' sx={{ mb: 5 }}>
            <Grid item xs={12} md={10}>
              {renderDateTies()}
            </Grid>
          </Grid>
          <Grid container spacing={0} direction='column' justifyContent='center' alignItems='center'>
            <Grid item xs={12}>
            </Grid>
          </Grid>
        </Container>
        <Dialog
          open={rulesOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleRulesClose}
          aria-describedby='alert-dialog-slide-description'
          sx={{ mb: 3 }}
        >
          <DialogTitle>Säännöt</DialogTitle>
          <DialogContent>
            {league?.regulations ? (
              league?.regulations.startsWith('<') ? (
                <div dangerouslySetInnerHTML={{ __html: league.regulations }}></div>
              ) : (
                <MuiMarkdown>{league?.regulations.replaceAll('_n', '\n')}</MuiMarkdown>
              )
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRulesClose}>Sulje</Button>
          </DialogActions>
        </Dialog>
      </Box>
    )
  } else {
    return (
      <Box
        sx={{
          width: '100vw',
          minHeight: '100vh',
          height: '100%',
          bgcolor: 'background.default',
          pb: 10,
        }}
      >
        <Container
          sx={{
            bgcolor: 'background.default',
            color: 'text.primary',
          }}
        >
          <Navigation />
          <Grid
            container
            spacing={0}
            direction='column'
            alignItems='center'
            justifyContent='center'
            style={{ minHeight: '100vh' }}
          >
            <Grid item xs={3}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Container>
      </Box>
    )
  }
}

const withTeams = compose(
  firebaseConnect((props) => [
    {
      path: 'customers/' + props.appconfig.customerid + '/tournaments/teams',
    },
    {
      path: 'customers/' + props.appconfig.customerid + '/tournaments/fixtures',
    },
    {
      path: 'customers/' + props.appconfig.customerid + '/locations',
    },
    {
      path: 'customers/' + props.appconfig.customerid + '/players',
    },
    {
      path: 'customers/' + props.appconfig.customerid + '/configs/leagues',
    },
  ]),
  connect((state, props) => {
    if (
      state.firebase.ordered.customers &&
      state.firebase.ordered.customers[props.appconfig.customerid] &&
      state.firebase.ordered.customers[props.appconfig.customerid].configs &&
      state.firebase.ordered.customers[props.appconfig.customerid].locations &&
      state.firebase.ordered.customers[props.appconfig.customerid].players &&
      state.firebase.ordered.customers[props.appconfig.customerid].tournaments &&
      state.firebase.ordered.customers[props.appconfig.customerid].tournaments.teams
    ) {

      const _groups = state.firebase.ordered.customers[props.appconfig.customerid].tournaments.teams
        .filter((t) => filterGroup(t, state))
        .map((t) => t.value.group)
        .filter((v, i, a) => a.indexOf(v) === i)
        .filter((x) => x)
        .sort()

      const _teams = state.firebase.ordered.customers[props.appconfig.customerid].tournaments.teams.filter((t) =>
        filterLeagueTeam(t, state)
      )
      console.log('_teams', _teams)
      let nextDay = null
      let _fixtures = []
      let matchDates = []
      const lastPublishedDay = parseISO(props.appconfig.lastpublishedday || '2030-06-01')
      if (state.firebase.ordered.customers[props.appconfig.customerid].tournaments.fixtures) {
        _fixtures = state.firebase.ordered.customers[props.appconfig.customerid].tournaments.fixtures.filter((t) => {
          return filterLeagueFixtures(t, state)
        }).filter((t) => {
          if (!t.value.time) return true
          return parseISO(t.value.time) < lastPublishedDay
        })


        _fixtures.sort((a, b) => {
          if (a.value.time && b.value.time) {
            return parseISO(a.value.time) - parseISO(b.value.time)
          } else if (a.value.time) {
            return -1
          } else if (b.value.time) {
            return 1
          } else {
            return a.value.round - a.value.round
          }
        })
        const startOfToday = new Date()
        startOfToday.setMinutes(0)
        startOfToday.setHours(0)
        // console.log(startOfToday)
        matchDates = _fixtures.reduce((ac, cu) => {
          if (cu.value.time) {
            const fixDate = parseISO(cu.value.time)
            const dateString = format(fixDate, 'yyyy-MM-dd')
            if (!ac.includes(dateString)) ac.push(dateString)
            if (!nextDay && isAfter(fixDate, startOfToday)) nextDay = fixDate
          }
          return ac
        }, [])
        // const nextDayFixture = _fixtures.find((x) => isAfter(parseISO(x.value.time), startOfToday))
        // console.log(nextDayFixture)
        // if (nextDayFixture) nextDay = parseISO(nextDayFixture.value.time)
        if (!nextDay) nextDay = new Date()
      }

      return {
        players: state.firebase.ordered.customers[props.appconfig.customerid].players.map((r) => {
          return { id: r.key, ...r.value }
        }),
        fixtures: _fixtures,
        teams: _teams,
        groups: _groups,
        // auth: state.firebase.auth,
        update: props.firebase.update,
        push: props.firebase.push,
        dispatch: props.firebase.dispatch,
        remove: props.firebase.remove,
        selections: state.sport,
        locations: state.firebase.ordered.customers[props.appconfig.customerid].locations,
        nextDay,
        matchDates,
        league: state.sport?.league?.value,
        leagues: state.firebase.ordered.customers[props.appconfig.customerid].configs.leagues.filter(
          (x) => x.value.state === 'published'
        ),
      }
    } else {
      return {
        players: [],
        fixtures: [],
        teams: [],
        groups: [],
        // auth: state.firebase.auth,
        update: props.firebase.update,
        push: props.firebase.push,
        dispatch: props.firebase.dispatch,
        remove: props.firebase.remove,
        selections: state.sport,
        locations: [],
        nextDay: new Date(),
        matchDates: [],
        league: null,
        leagues: [],
      }
    }
  })
)

export default withAppconfig(withTeams(FixturesPage))
