import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import Navigation from './Navigation'
import { Button, Card, CardContent, Container } from '@mui/material'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Selector from './components/Selector'
import { TeamColors } from '@cintoiacom/team-colors'
import { useHistory } from 'react-router'
import { filterTeam } from './StateFilters'
import withAppconfig from './AppContext'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'

const Item = styled(Card)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  '&:hover': {
    background: theme.palette.text.secondary
 }
}))


function TeamsPage({ teams }) {
  // console.log(teams)
  const history = useHistory()

  return (
    <Box
      sx={{
        width: '100vw',
        minHeight: '100vh',
        height: '100%',
        bgcolor: 'background.default',
        pb: 10
      }}
    >
      <Container
        sx={{
          bgcolor: 'background.default',
          color: 'text.primary',
        }}
      >
        <Navigation />
        <Grid container spacing={0} sx={{ mb: 2, mt: 2 }} alignItems="center"  justify="flex-end">
          <Grid item xs={6} sm={6} md={6}  >
            <Selector />
          </Grid>
          <Grid item xs={6} sm={6} md={6} >
            <Button variant='contained' color='primary' onClick={() => history.push('/reserves')}>
            <PeopleAltIcon fontSize='x-small' />
            <Typography variant='caption'>Tuuraajahaku</Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid container direction='row' spacing={1}>
          {teams
            ? teams
                .sort((a, b) => {
                  if (a.value.name < b.value.name) {
                    return -1
                  }
                  if (a.value.name > b.value.name) {
                    return 1
                  }
                  return 0
                })
                .map((t) => {
                  return (
                  <Grid item xs={6} sm={4} md={3}>
                      <Item style={{ cursor: 'pointer'}} onClick={() => history.push('/teams/' + t.key)}>
                      {/* <Card key={t.key}> */}
                        <CardContent>
                          <TeamColors
                            width={24}
                            height={24}
                            color1={t?.value?.logo?.color1 || 0}
                            color2={t?.value?.logo?.color2 || 0}
                            color3={t?.value?.logo?.color3 || 0}
                            shape={t?.value?.logo?.shape || 1}
                            template={t?.value?.logo?.template || 0}
                          />
                        </CardContent>
                        <CardContent>
                          <Typography variant="h6" >{t.value.name}</Typography>
                        </CardContent>
                      {/* </Card> */}
                    </Item>
                  </Grid>)
                })
            : null}
        </Grid>
      </Container>
    </Box>
  )
}

const withTeams = compose(
  firebaseConnect((props) => {
    return [
      {
        path: 'customers/' + [props.appconfig.customerid] + '/tournaments/teams',
      }
    ]
  }),
  connect((state, props) => {
    if (
      state.firebase.ordered.customers &&
      state.firebase.ordered.customers[props.appconfig.customerid]?.tournaments?.teams &&
      state.sport.sport &&
      state.sport.gender &&
      // state.sport.season &&
      state.sport.division
    ) {
      const _teams = state.firebase.ordered.customers[props.appconfig.customerid].tournaments.teams.filter((t) => filterTeam(t, state))
      return {
        teams: _teams,
      }
    } else {
      return {
        teams: [],
      }
    }
  })
)

export default withAppconfig(withTeams(TeamsPage))
