import { format, parseISO } from 'date-fns'
import { TeamColors } from '@cintoiacom/team-colors'

const renderRubberTable = (r, hometeam, awayteam) => {
  let homeName = ''
  let awayName = ''
  if (r.home1 && r.home1.id.length > 0) {
    if (r.type === '2p') {
      homeName = r.home1.name
      awayName = r.away1.name
    } else {
      homeName = r.home1.name + ' / ' + r.home2.name
      awayName = r.away1.name + ' / ' + r.away2.name
    }
  } else {
    if (r.type === '2p') {
      homeName = 'Kaksinpeli'
    } else {
      homeName = 'Nelinpeli'
    }
    if (r.time) {
      awayName = format(parseISO(r.time), 'HH:mm') + ' / ' + r.court
    }
  }
  return (
    <table style={{ borderCollapse: 'collapse', marginBottom: 10 }} key={Math.random().toString()}>
      <tbody>
        <tr style={{ border: '1px solid #d1d5d7' }}>
          {r.score ? (
            <td
              style={{
                borderLeft: '1px solid #d1d5d7',
                borderRight: '0px solid #d1d5d7',
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              <TeamColors
                width={12}
                height={12}
                color1={hometeam?.value?.logo?.color1 || 0}
                color2={hometeam?.value?.logo?.color2 || 0}
                color3={hometeam?.value?.logo?.color3 || 0}
                shape={hometeam?.value?.logo?.shape || 1}
                template={hometeam?.value?.logo?.template || 0}
              />
            </td>
          ) : null}
          <td
            style={{
              borderLeft: '0px solid #d1d5d7',
              borderRight: '1px solid #d1d5d7',
              paddingLeft: 5,
              paddingRight: 5,
            }}
          >
            {homeName}
          </td>
          {r.score
            ? r.score.map((set) => {
                return (
                  <td style={{ borderRight: '1px solid #d1d5d7', paddingLeft: 5, paddingRight: 5 }}>{set.home}</td>
                )
              })
            : null}
        </tr>
        <tr style={{ border: '1px solid #d1d5d7' }}>
          {r.score ? (
            <td
              style={{
                borderLeft: '1px solid #d1d5d7',
                borderRight: '0px solid #d1d5d7',
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              <TeamColors
                width={12}
                height={12}
                color1={awayteam?.value?.logo?.color1 || 0}
                color2={awayteam?.value?.logo?.color2 || 0}
                color3={awayteam?.value?.logo?.color3 || 0}
                shape={awayteam?.value?.logo?.shape || 1}
                template={awayteam?.value?.logo?.template || 0}
              />
            </td>
          ) : null}
          <td
            style={{
              borderRight: '1px solid #d1d5d7',
              borderLeft: '0px solid #d1d5d7',
              paddingLeft: 5,
              paddingRight: 5,
            }}
          >
            {awayName}
          </td>
          {r.score
            ? r.score.map((set) => {
                return (
                  <td style={{ borderRight: '1px solid #d1d5d7', paddingLeft: 5, paddingRight: 5 }}>{set.away}</td>
                )
              })
            : null}
        </tr>
      </tbody>
    </table>
  )
}

export { renderRubberTable }