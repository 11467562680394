import React from 'react'
import { AppconfigContext } from './appconfig-context'

export default function withAppconfig(Component) {
    return function contextComponent(props) {
        return (
            <AppconfigContext.Consumer>
                {context => <Component {...props} appconfig={context} />}
            </AppconfigContext.Consumer>
        )
    }
}