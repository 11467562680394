import { pointrules } from "./config";

const getTieScores = (rubbers, sport) => {
  let pointrule = pointrules.find((r) => r.sport === sport.toLowerCase() && r.rubbers === rubbers.length)
  const diffs = rubbers.reduce(
    (ac, cu) => {
      if (cu.score) {
        let homeoverride = false
        if (typeof cu.homestandingoverride !== 'undefined') {
          ac.homepoints = ac.homepoints + cu.homestandingoverride
          homeoverride = true
        }
        let awayoverride = false
        if (typeof cu.awaystandingoverride !== 'undefined') {
          ac.awaypoints = ac.awaypoints + cu.awaystandingoverride
          awayoverride = true
        }

        const scores = cu.score.reduce(
          (acs, cs, index) => {
            if (index === 2 && pointrule.lastsetgames === 1) {
              if (cs.home > cs.away) {
                acs.homegames = acs.homegames + 1
              } else {
                acs.awaygames = acs.awaygames + 1
              }
            } else {
              acs.homegames = acs.homegames + cs.home
              acs.awaygames = acs.awaygames + cs.away
            }
            if (cs.home > cs.away) {
              if (!homeoverride) acs.homepoints = acs.homepoints + pointrule.setwin
            } else if (cs.home < cs.away) {
              if (!awayoverride) acs.awaypoints = acs.awaypoints + pointrule.setwin
            } else {
              if (!homeoverride) acs.homepoints = acs.homepoints + pointrule.setdraw
              if (!awayoverride) acs.awaypoints = acs.awaypoints + pointrule.setdraw
            }
            return acs
          },
          { homegames: 0, awaygames: 0, homepoints: 0, awaypoints: 0 }
        )

        ac.homegames = ac.homegames + scores.homegames
        ac.awaygames = ac.awaygames + scores.awaygames
        ac.homepoints = ac.homepoints + scores.homepoints
        ac.awaypoints = ac.awaypoints + scores.awaypoints
        if (pointrule.clearwin) {
          if (scores.homepoints === 0) {
            if (!awayoverride) ac.awaypoints = ac.awaypoints + pointrule.clearwin
          } else if (scores.awaypoints === 0) {
            if (!homeoverride) ac.homepoints = ac.homepoints + pointrule.clearwin
          }
        }
      }
      return ac
    },
    { homegames: 0, awaygames: 0, homepoints: 0, awaypoints: 0 }
  )
  return diffs
}

export { getTieScores }