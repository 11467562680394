import TocIcon from '@mui/icons-material/Toc';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

export const firebaseConfig = {
  apiKey: "AIzaSyBPqn6usEbZYVVADkNfASJY5Ag95kgQOkE",
  authDomain: "falcon-tournament.firebaseapp.com",
  databaseURL: "https://falcon-tournament-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "falcon-tournament",
  storageBucket: "falcon-tournament.appspot.com",
  messagingSenderId: "272757856416",
  appId: "1:272757856416:web:77ce8d9ec2b09ef759d1c5",
  measurementId: "G-FDJLBG3YTT"
};

export const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: false,
  enableClaims: true
}

export const seasons = [
  { key: '20212022', title: '2021/22' }
]

export const genders = [
  { key: 'men', title: 'Miehet' },
  { key: 'women', title: 'Naiset' },
  { key: 'mixed', title: 'Mixed' },
  { key: 'open', title: 'Avoin' },
]

export const sports = [
  { key: 'tennis', title: 'Tennis' },
  { key: 'padel', title: 'Padel' },
  { key: 'salibandy', title: 'Salibandy' },
]

export const divisions = [
  { key: '1', title: 'Mestaruussarja' },
  { key: '2', title: '1. divisioona' },
  { key: '3', title: '2. divisioona' },
  { key: '4', title: '3. divisioona' },
  { key: '5', title: '4. divisioona' },
  { key: '99', title: 'Ei sarjatasoa' }
]

export const pointrules = [
  { sport: 'tennis', rubbers: 3, setwin: 1, setdraw: 0.5 },
  { sport: 'tennis', rubbers: 1, setwin: 1, setdraw: 0, clearwin: 1, lastsetgames: 1 },
  { sport: 'padel', rubbers: 1, setwin: 1, setdraw: 0.5, clearwin: 0, lastsetgames: 0 },
]

export const lajit = ['TENNIS', 'PADEL']
export const routes = [
  { id: 1, name: 'Ottelut', path: '/fixtures', icon: <SportsBaseballIcon/> },
  { id: 2, name: 'Sarjataulukot', path: '/standings', icon: <TocIcon style={{ transform: 'rotate(180deg)'}} /> },
  { id: 3, name: 'Joukkueet', path: '/teams', icon: <PeopleAltIcon /> },
]

// eslint-disable-next-line import/no-anonymous-default-export
export default { firebaseConfig, rrfConfig, seasons, genders, sports, divisions, pointrules, lajit, routes }