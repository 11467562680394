import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { CookiesProvider } from 'react-cookie'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/database'
import 'firebase/compat/functions'
// import 'firebase/compat/analytics'
import 'firebase/compat/performance'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import createStore from './createStore'
import { firebaseConfig, rrfConfig } from './config'
import { createFirestoreInstance } from 'redux-firestore'
import { Provider } from 'react-redux'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { fi } from 'date-fns/locale'

try {
  firebase.initializeApp(firebaseConfig)
  firebase.performance()
  firebase.analytics()
} catch (err) {}

if (process.env.NODE_ENV !== 'development') console.log = () => {}

const store = createStore()

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <CookiesProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={fi}>
            <App />
          </LocalizationProvider>
        </CookiesProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
