import * as React from 'react'
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles'
import { ColorModeContext } from './theme-context'
import { BrowserRouter } from 'react-router-dom'
import AppNavigator from './AppNavigator'
import { useCookies } from 'react-cookie'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { AppconfigContext } from './appconfig-context'

const getDesignTokens = (mode, appconfig) => {
  if (appconfig?.palette) {
    return { palette: { ...appconfig?.palette, mode }  }
  } else {
    return {
      palette: {
        primary: {
          light: '#d444e7',
          main: '#d444e7', //#f44336
          dark: '#000000',
          contrastText: '#fff',
        },
        secondary: {
          light: '#ff7961',
          main: '#f44336',
          dark: '#ba000d',
          contrastText: '#000',
        },
        mode,
      },
    }
  }
}

function App({ appconfig }) {
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['sport', 'mode', 'leagueid', 'favoriteteam'])
  const [mode, setMode] = React.useState(cookies.mode ? cookies.mode : 'dark')
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
      },
    }),
    []
  )

  // Update the theme only if the mode changes
  let theme = React.useMemo(() => createTheme(getDesignTokens(mode, appconfig?.[document.location.hostname])), [mode, appconfig])

  theme = responsiveFontSizes(theme)

  if (isLoaded(appconfig)) {
    return (
      <ColorModeContext.Provider value={colorMode}>
        <AppconfigContext.Provider value={appconfig[document.location.hostname]}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <AppNavigator />
            </BrowserRouter>
          </ThemeProvider>
        </AppconfigContext.Provider>
      </ColorModeContext.Provider>
    )
  } else {
    return <>Loading</>
  }
}

const withConfig = compose(
  firestoreConnect((props) => [
    {
      collection: 'inits',
      doc: 'falcon-tournament',
      storeAs: 'init',
    },
  ]),
  connect(({ firestore }) => {
    // console.log('firestore.data[init]', firestore.data['init'])
    return {
      appconfig: firestore.data['init'],
    }
  })
)

export default withConfig(App)
