import * as React from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import config, { seasons, genders, divisions } from '../config'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { filterGroup } from '../StateFilters'
import withAppconfig from '../AppContext'

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

function Selector({ selections, dispatch, groups, divs, gens }) {
  // console.log('selections', selections)
  const [group, setGroup] = React.useState(selections.group || '')
  // const [gender, setGender] = React.useState(selections.gender/* || config.genders[0].key*/)
  const [gender, setGender] = React.useState(selections.gender || '')
  const [division, setDivision] = React.useState(selections.division || config.divisions[0].key)
  const [season, setSeason] = React.useState(selections.season || config.seasons[0].key)
  const [leagueDivisions, setLeagueDivisions] = React.useState([])
  const [leagueGenders, setLeagueGenders] = React.useState([])

  const handleGenderChange = (event) => {
    setGender(event.target.value)
  }

  const handleDivisionChange = (event) => {
    setDivision(event.target.value)
  }

  const handleSeasonChange = (event) => {
    setSeason(event.target.value)
  }

  const handleGroupChange = (event) => {
    setGroup(event.target.value)
  }

  React.useEffect(() => {
    if (divs) {
      if (divs.length === 1 && divs.includes('99')) {
        setDivision('99')       
      } else {
        setLeagueDivisions(divisions.filter((d) => divs.includes(d.key)))
      }
    }
  }, [divs, setLeagueDivisions])

  React.useEffect(() => {
    // console.log('gender update', gens, gender)
    if (gens) {
      const _l = genders.filter((d) => gens.includes(d.key))
      setLeagueGenders(_l)
      if (_l.length === 0 || !_l.find((x) => x.key === gender)) {
        setGender('')
      }
    }
  }, [gens, setLeagueGenders, gender, setGender])

  React.useEffect(() => {
    if (division) {
      dispatch({
        type: 'UPDATE_DIVISION',
        payload: division,
      })
    }
    if (gender) {
      dispatch({
        type: 'UPDATE_GENDER',
        payload: gender,
      })
    }
    if (season) {
      dispatch({
        type: 'UPDATE_SEASON',
        payload: season,
      })
    }
    if (group) {
      dispatch({
        type: 'UPDATE_GROUP',
        payload: group,
      })
    } else {
      dispatch({
        type: 'CLEAR_GROUP',
      })
    }
  }, [gender, division, season, dispatch, group])

  return (
    <Item>
      <FormControl variant='standard' sx={{ m: 1, minWidth: 120, fontSize: 5 }}>
        <Select
          labelId='gender'
          id='gender-simple-select'
          value={gender}
          displayEmpty
          onChange={handleGenderChange}
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{ fontSize: 14 }}
        >
          <MenuItem disabled value=''>
            <em>Sarja</em>
          </MenuItem>
          {leagueGenders.map((g) => (
            <MenuItem key={g.key} value={g.key} sx={{ fontSize: 14 }}>
              {g.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {leagueDivisions && leagueDivisions.length > 0 ?
        <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
          <Select
            labelId='division'
            id='division-simple-select'
            value={division}
            onChange={handleDivisionChange}
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{ fontSize: 14 }}
          >
            {leagueDivisions.map((g) => (
              <MenuItem key={g.key} value={g.key} sx={{ fontSize: 14 }}>
                {g.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      : null}
      {groups.length > 0 ? (
        !groups.includes(group) ? (
          setGroup(groups[0])
        ) : (
          <FormControl variant='standard' sx={{ m: 1, minWidth: 50 }}>
            <Select
              labelId='group'
              id='group-simple-select'
              value={group}
              onChange={handleGroupChange}
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{ fontSize: 14 }}
            >
              {groups.map((g) => (
                <MenuItem key={g} value={g} sx={{ fontSize: 14 }}>
                  {g}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      ) : group !== null ? (
        setGroup(null)
      ) : null}
      {seasons.length > 1 ? (
        <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
          <Select
            labelId='season'
            id='season-simple-select'
            value={season}
            onChange={handleSeasonChange}
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{ fontSize: 14 }}
          >
            {seasons.map((g) => (
              <MenuItem key={g.key} value={g.key} sx={{ fontSize: 14 }}>
                {g.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}
    </Item>
  )
}



const withSelections = compose(
  firebaseConnect((props) => [
    {
      path: 'customers/' + props.appconfig.customerid + '/tournaments/teams',
    },
    {
      path: 'customers/' + props.appconfig.customerid + '/tournaments/fixtures',
    },
  ]),
  connect((state, props) => {
    if (
      state.firebase.ordered.customers &&
      state.firebase.ordered.customers.lahiliiga &&
      state.firebase.ordered.customers[props.appconfig.customerid].tournaments &&
      state.firebase.ordered.customers[props.appconfig.customerid].tournaments.teams &&
      state.sport.sport //&&
      // state.sport.gender &&
      // state.sport.season &&
      // state.sport.division
    ) {
      const _genders = state.firebase.ordered.customers[props.appconfig.customerid].tournaments.teams.reduce((a, c) => {
        if (c.value.leagueid === state.sport?.league?.key) {
          if (!a.includes(c.value.gender)) a.push(c.value.gender)
        }
        return a
      },[])

      // console.log('_genders', _genders)


      const _groups = state.firebase.ordered.customers[props.appconfig.customerid].tournaments.teams
        // .filter((t) => filterGroup(t, state))
        .filter((t) => {
          // console.log(state.sport)
          return filterGroup(t, state)
        })
        .map((t) => {
          if (t.value.group) {
            return t.value.group.trim()
          } else {
            return null
          }
        })
        .filter((v, i, a) => a.indexOf(v) === i)
        .filter((x) => x)
        .sort()

      const _divisions = state.firebase.ordered.customers[props.appconfig.customerid].tournaments.teams.filter(
        (t) => {
          if (t.value.sport) {
            if (!t.value.leagueid) {
              return (
                t.value.sport === state.sport.sport.toLowerCase() && t.value.gender === state.sport.gender
              )
            } else {
              return (
                t.value.leagueid === state.sport?.league?.key && t.value.gender === state.sport.gender
              )
            }
          } else {
            return (
              t.value.leagueid === state.sport?.league?.key && t.value.gender === state.sport.gender
            )
          }
        }
      ).map((t) => t.value.division).filter((v, i, a) => a.indexOf(v) === i)
      .filter((x) => x)
      .sort()
      
      return {
        selections: state.sport,
        dispatch: props.firebase.dispatch,
        groups: _groups,
        divs: _divisions,
        gens: _genders
      }
    } else {
      return {
        gens: [],
        groups: [],
        divs: [],
        selections: state.sport,
        dispatch: props.firebase.dispatch,
      }
    }
  })
)

export default withAppconfig(withSelections(Selector))
