import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
// import Divider from '@mui/material/Divider'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import { styled } from '@mui/material/styles'
import Navigation from './Navigation'
import { CircularProgress, Container, Paper } from '@mui/material'
import { connect } from 'react-redux'
import { firebaseConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import { format, parseISO, formatISO, add } from 'date-fns'
import { fi } from 'date-fns/locale'
import { getTieScores } from './Scores'
import { TeamColors } from '@cintoiacom/team-colors'
// import { useHistory } from 'react-router'
// import FilterListIcon from '@mui/icons-material/FilterList'
import { useParams } from 'react-router-dom'
import { filterFixture } from './StateFilters'
import withAppconfig from './AppContext'
import ICalendarLink from "react-icalendar-link"

const CalLink = styled(ICalendarLink)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  fontSize: 'small'
}))

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const TieContainer = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}))

function TeamPage({ teams, fixtures, locations, players }) {
  const { teamid } = useParams()
  // const history = useHistory()
  const [team, setTeam] = useState(null)

  useEffect(() => {
    const _team = teams.find((t) => t.key === teamid)
    if (_team) setTeam(_team)
  }, [teamid, setTeam, teams])

  const loaded = isLoaded(fixtures)

  const renderRubberTable = (r, hometeam, awayteam) => {
    let homeName = ''
    let awayName = ''
    if (r.home1 && r.home1.id.length > 0) {
      if (r.type === '2p') {
        homeName = r.home1.name
        awayName = r.away1.name
      } else {
        homeName = r.home1.name + ' / ' + r.home2.name
        awayName = r.away1.name + ' / ' + r.away2.name
      }
    } else {
      if (r.type === '2p') {
        homeName = 'Kaksinpeli'
      } else {
        homeName = 'Nelinpeli'
      }
      if (r.time) {
        awayName = format(parseISO(r.time), 'HH:mm') + ' / ' + r.court
      }
    }
    return (
      <table style={{ borderCollapse: 'collapse', marginBottom: 10 }} key={Math.random().toString()}>
        <tbody>
          <tr style={{ border: '1px solid #d1d5d7' }}>
            <td
              style={{
                borderLeft: '1px solid #d1d5d7',
                borderRight: '0px solid #d1d5d7',
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              <TeamColors
                width={12}
                height={12}
                color1={hometeam?.value?.logo?.color1 || 0}
                color2={hometeam?.value?.logo?.color2 || 0}
                color3={hometeam?.value?.logo?.color3 || 0}
                shape={hometeam?.value?.logo?.shape || 1}
                template={hometeam?.value?.logo?.template || 0}
              />
            </td>
            <td
              style={{
                borderLeft: '0px solid #d1d5d7',
                borderRight: '1px solid #d1d5d7',
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              {homeName}
            </td>
            {r.score
              ? r.score.map((set) => {
                  return (
                    <td
                      style={{ borderRight: '1px solid #d1d5d7', paddingLeft: 5, paddingRight: 5 }}
                      key={'score-' + Math.random().toString()}
                    >
                      {set.home}
                    </td>
                  )
                })
              : null}
          </tr>
          <tr style={{ border: '1px solid #d1d5d7' }}>
            <td
              style={{
                borderLeft: '1px solid #d1d5d7',
                borderRight: '0px solid #d1d5d7',
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              <TeamColors
                width={12}
                height={12}
                color1={awayteam?.value?.logo?.color1 || 0}
                color2={awayteam?.value?.logo?.color2 || 0}
                color3={awayteam?.value?.logo?.color3 || 0}
                shape={awayteam?.value?.logo?.shape || 1}
                template={awayteam?.value?.logo?.template || 0}
              />
            </td>
            <td
              style={{
                borderRight: '1px solid #d1d5d7',
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              {awayName}
            </td>
            {r.score
              ? r.score.map((set) => {
                  return (
                    <td
                      style={{ borderRight: '1px solid #d1d5d7', paddingLeft: 5, paddingRight: 5 }}
                      key={'score-' + Math.random().toString()}
                    >
                      {set.away}
                    </td>
                  )
                })
              : null}
          </tr>
        </tbody>
      </table>
    )
  }

  const renderTeamPlayers = (teamId) => {
    const _team = teams.find((t) => teamId === t.key)
    const _players = players.filter((p) => _team.value.players && _team.value.players.includes(p.id))

    return (
      <>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {_players.map((p) => (
            <>
              <ListItem key={p.id}>
                <ListItemAvatar>
                  <Avatar alt={p.firstname + ' ' + p.lastname} src='/static/images/avatar/1.jpg' />
                </ListItemAvatar>
                <ListItemText primary={p.firstname + ' ' + p.lastname} />
              </ListItem>
              {/* <Divider variant='inset' component='li' /> */}
            </>
          ))}
        </List>
      </>
    )
  }

  const renderTie = (tie) => {
    const location = tie.value.location ? locations.find((l) => l.key === tie.value.location) : null
    let score = null
    if (tie.value.rubbers && tie.value.rubbers.length > 0 && tie.value.rubbers[0].score) {
      const { homepoints, awaypoints } = getTieScores(tie.value.rubbers, tie.value.sport)
      score = (
        <Typography ml={1} mr={1} color={'lightgreen'} variant='subtitle1' component='span'>
          {homepoints} - {awaypoints}
        </Typography>
      )
    }
    const hometeam = teams.find((t) => t.key === tie.value.home.id)
    const awayteam = teams.find((t) => t.key === tie.value.away.id)
    const duration = tie.value.rubbers && tie.value.rubbers.length > 1 ? { hours: 2 } : { hours: 1, minutes: 30 }
    return (
      <TieContainer key={tie.key}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant='subtitle2' component='span'>
                  {tie.value.home.name}{' '}
                  <TeamColors
                    width={12}
                    height={12}
                    color1={hometeam?.value?.logo?.color1 || 0}
                    color2={hometeam?.value?.logo?.color2 || 0}
                    color3={hometeam?.value?.logo?.color3 || 0}
                    shape={hometeam?.value?.logo?.shape || 1}
                    template={hometeam?.value?.logo?.template || 0}
                  />{' '}
                  -{' '}
                  <TeamColors
                    width={12}
                    height={12}
                    color1={awayteam?.value?.logo?.color1 || 0}
                    color2={awayteam?.value?.logo?.color2 || 0}
                    color3={awayteam?.value?.logo?.color3 || 0}
                    shape={awayteam?.value?.logo?.shape || 1}
                    template={awayteam?.value?.logo?.template || 0}
                  />{' '}
                  {tie.value.away.name} {score}
                </Typography>
              </Grid>
              {!score ? (
                <>
                  <Grid item xs={4} sm={2} md={2}>
                    <Typography variant='body2' component='div'>
                      {tie.value.time
                        ? format(parseISO(tie.value.time), 'eee d.M.yyyy p', {
                            locale: fi,
                        })
                        : null}
                    </Typography>
                    {tie.value.time ? <CalLink event={{ title: tie.value.home.name + ' - ' + tie.value.away.name + ' Lähiliiga', startTime: tie.value.time, endTime: formatISO(add(parseISO(tie.value.time), duration)), location: location.value.name }}>Lisää kalenteriin</CalLink> : null}
                  </Grid>
                  <Grid item xs={4} sm={2} md={2}>
                    <Typography variant='body2' component='div'>
                      {location ? location.value.name : null} - Kierros {tie.value.round + 1}
                    </Typography>

                  </Grid>
                </>
              ) : null}
              {tie.value.note ? <Grid item xs={12}><Chip label={tie.value.note} size="small"/></Grid> : null}
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item>
              {tie.value.rubbers.sort((a, b) => {
                  if (a.time < b.time) { return -1 }
                  if (a.time > b.time) { return 1 }
                  return 0
                }).map((r) => renderRubberTable(r, hometeam, awayteam))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </TieContainer>
    )
  }

  if (team) {
    return (
      <Box
        sx={{
          width: '100vw',
          minHeight: '100vh',
          height: '100%',
          bgcolor: 'background.default',
          pb: 10
        }}
      >
        <Container
          sx={{
            bgcolor: 'background.default',
            color: 'text.primary',
          }}
        >
          <Navigation />
          <Paper elevation={10} sx={{ my: 2, padding: 4 }}>
            <Grid container spacing={1}>
              <Grid item xs={2} md={1}>
                <TeamColors
                  width={48}
                  height={48}
                  color1={team?.value?.logo?.color1 || 0}
                  color2={team?.value?.logo?.color2 || 0}
                  color3={team?.value?.logo?.color3 || 0}
                  shape={team?.value?.logo?.shape || 1}
                  template={team?.value?.logo?.template || 0}
                />
              </Grid>
              <Grid item xs={10} md={11}>
                <Typography variant='h3'>{team?.value?.name}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant='subtitle1'>Pelaajat</Typography>
                {renderTeamPlayers(team.key)}
              </Grid>
              <Grid item xs={12} md={6}>
              {/* <Button onClick={() => history.push('/shirt/' + team.key)}>Paita</Button> */}
              </Grid>
            </Grid>
          </Paper>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              {fixtures && fixtures.length > 0 ? (
                fixtures
                  .filter((x) => {
                    if (team.key) {
                      return x.value.home.id === team.key || x.value.away.id === team.key
                    } else {
                      return true
                    }
                  })
                  .map((f) => renderTie(f))
              ) : loaded ? (
                <Grid item xs={12} sm={6} md={3}>
                  <Item></Item>
                </Grid>
              ) : (
                <CircularProgress />
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    )
  } else {
    return (
      <>
        <CircularProgress />
      </>
    )
  }
}


const withTeams = compose(
  firebaseConnect((props) => [
    {
      path: 'customers/' + props.appconfig.customerid + '/tournaments/teams',
    },
    {
      path: 'customers/' + props.appconfig.customerid + '/tournaments/fixtures',
    },
    {
      path: 'customers/' + props.appconfig.customerid + '/locations',
    },
    {
      path: 'customers/' + props.appconfig.customerid + '/players',
    },
  ]),
  connect((state, props) => {
    if (
      state.firebase.ordered.customers &&
      state.firebase.ordered.customers.lahiliiga &&
      state.firebase.ordered.customers[props.appconfig.customerid].locations &&
      state.firebase.ordered.customers[props.appconfig.customerid].players &&
      state.firebase.ordered.customers[props.appconfig.customerid].tournaments &&
      state.firebase.ordered.customers[props.appconfig.customerid].tournaments.teams
    ) {
      let _fixtures = []
      if (state.firebase.ordered.customers[props.appconfig.customerid].tournaments.fixtures) {
        _fixtures = state.firebase.ordered.customers[props.appconfig.customerid].tournaments.fixtures.filter((t) => filterFixture(t, state))
        _fixtures.sort((a, b) => {
          if (a.value.time && b.value.time) {
            return parseISO(a.value.time) - parseISO(b.value.time)
          } else if (a.value.time) {
            return -1
          } else if (b.value.time) {
            return 1
          } else {
            return a.value.round - a.value.round
          }
        })
      }

      return {
        players: state.firebase.ordered.customers[props.appconfig.customerid].players.map((r) => {
          return { id: r.key, ...r.value }
        }),
        fixtures: _fixtures,
        teams: state.firebase.ordered.customers[props.appconfig.customerid].tournaments.teams,
        locations: state.firebase.ordered.customers[props.appconfig.customerid].locations,
      }
    } else {
      return {
        players: [],
        fixtures: [],
        teams: [],
        locations: [],
      }
    }
  })
)

export default withAppconfig(withTeams(TeamPage))
