
const fixTypos = (t) => {
  if (t.value.group) t.value.group = t.value.group.trim()
  return t
}

const filterRegulations = (t, state) => {
  if (t.value.leagueid) {
    return (
      t.value.leagueid === state.sport?.league?.key &&
      t.value.gender === state.sport.gender &&
      t.value.division === state.sport.division
    )
  }
  return false
}

const filterGenders = (t, state) => {
  if (t.value.leagueid) {
    return (
      t.value.leagueid === state.sport?.league?.key &&
      t.value.gender === state.sport.gender &&
      t.value.division === state.sport.division
    )
  }
  return false
}


const filterTeam = (_t, state) => {
  const t = fixTypos(_t)
  if (t.value.leagueid) {
    if (state.sport.group) {
      return (
        t.value.leagueid === state.sport?.league?.key &&
        t.value.gender === state.sport.gender &&
        // t.value.season === state.sport.season &&
        t.value.group === state.sport.group &&
        t.value.division === state.sport.division
      )
    } else {
      return (
        t.value.leagueid === state.sport?.league?.key &&
        t.value.gender === state.sport.gender &&
        // t.value.season === state.sport.season &&
        t.value.division === state.sport.division
      )
    }
  } else if (t.value.sport) {
    if (state.sport.group) {
      return (
        t.value.sport === state.sport.sport.toLowerCase() &&
        t.value.gender === state.sport.gender &&
        // t.value.season === state.sport.season &&
        t.value.group === state.sport.group &&
        t.value.division === state.sport.division
      )
    } else {
      return (
        t.value.sport === state.sport.sport.toLowerCase() &&
        t.value.gender === state.sport.gender &&
        // t.value.season === state.sport.season &&
        t.value.division === state.sport.division
      )
    }
  }
}

const filterLeagueTeam = (t, state) => {
  if (t.value.leagueid) {
    return t.value.leagueid === state.sport?.league?.key
  } else if (t.value.sport) {
    return t.value.sport === state.sport.sport.toLowerCase()
  }
}

const filterLeagueFixtures = (t, state) => {
  if (t.value.leagueid) {
    return t.value.leagueid === state.sport?.league?.key
  } else if (t.value.sport) {
    return t.value.sport === state.sport.sport.toLowerCase()
  }
}

const filterFixture = (_t, state) => {
  const t = fixTypos(_t)
  if (t.value.leagueid) {
    if (state.sport.group) {
      return (
        t.value.leagueid === state.sport?.league?.key &&
        t.value.gender === state.sport.gender &&
        // t.value.season === state.sport.season &&
        t.value.group === state.sport.group &&
        t.value.division === state.sport.division
      )
    } else {
      return (
        t.value.leagueid === state.sport?.league?.key &&
        t.value.gender === state.sport.gender &&
        // t.value.season === state.sport.season &&
        t.value.division === state.sport.division
      )
    }
  } else if (t.value.sport) {
    if (state.sport.group) {
      return (
        t.value.sport === state.sport.sport.toLowerCase() &&
        t.value.gender === state.sport.gender &&
        // t.value.season === state.sport.season &&
        t.value.group === state.sport.group &&
        t.value.division === state.sport.division
      )
    } else {
      return (
        t.value.sport === state.sport.sport.toLowerCase() &&
        t.value.gender === state.sport.gender &&
        // t.value.season === state.sport.season &&
        t.value.division === state.sport.division
      )
    }
  }
}

const filterGroup = (t, state) => {
  if (t.value.sport) {
    if (!t.value.leagueid) {
      return (
        t.value.sport === state.sport.sport.toLowerCase() &&
        t.value.gender === state.sport.gender &&
        // t.value.season === state.sport.season &&
        t.value.division === state.sport.division
      )
    } else {
      return (
        t.value.leagueid === state.sport?.league?.key &&
        t.value.gender === state.sport.gender &&
        // t.value.season === state.sport.season &&
        t.value.division === state.sport.division
      )
    }
  } else {
    return (
      t.value.leagueid === state.sport?.league?.key &&
      t.value.gender === state.sport.gender &&
      // t.value.season === state.sport.season &&
      t.value.division === state.sport.division
    )
  }
}

export { filterTeam, filterFixture, filterGroup, filterLeagueTeam, filterLeagueFixtures, filterRegulations, filterGenders }
