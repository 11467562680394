import * as React from 'react'
import Box from '@mui/material/Box'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
// import RestoreIcon from '@mui/icons-material/Restore';
// import FavoriteIcon from '@mui/icons-material/Favorite';
import HomeIcon from '@mui/icons-material/Home'
import { routes } from './config'
import { useHistory } from 'react-router'
import withAppconfig from './AppContext'
// import BrandIcon from './components/BrandIcon'

function SimpleBottomNavigation({ appconfig }) {
  const [value, setValue] = React.useState(0)
  const history = useHistory()

  React.useEffect(() => {
    let r = routes.find((x) => x.path === history.location.pathname)
    if (r) {
      setValue(r.id)
    } else {
      setValue(0)
    }
  }, [value, setValue, history])

  return (
    <Box
      sx={{ display: { xs: 'block', sm: 'none' }, position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 9999 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          // console.log(newValue)
          setValue(newValue)
        }}
        sx={{ paddingBottom: 'var(--toolbar-safe-space, env(safe-area-inset-bottom))' }}
      >
        <BottomNavigationAction label={appconfig.brand.text} icon={<HomeIcon />} onClick={() => history.push('/')} />
        {routes.map((r, i) => {
          return (
            <BottomNavigationAction key={r.name} onClick={() => history.push(r.path)} icon={r.icon} label={r.name} />
          )
        })}
      </BottomNavigation>
    </Box>
  )
}

export default withAppconfig(SimpleBottomNavigation)
