import React from 'react'
import { isLoaded } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router'
import FixturesPage from './FixturesPage'
import StandingsPage from './StandingsPage'
import AllFixturesPage from './AllFixturesPage'
import TeamsPage from './TeamsPage'
import TeamPage from './TeamPage'
import { Box, CircularProgress, Container, Grid } from '@mui/material'
import Navigation from './Navigation'
import ShirtPage from './ShirtPage'
import ReservesPage from './ReservesPage.jsx'

const enhance = connect((state, props) => {
  const { auth, profile } = state.firebase
  const { league } = state.sport
  return ({
    auth,
    profile,
    league
  })
})

// function PrivateRoute({ children, isLoggedIn, ...rest }) {
//   return (
//     <Route
//       {...rest}
//       render={({ location }) =>
//         isLoggedIn ? (
//           children
//         ) : (
//           <Redirect
//             to={{
//               pathname: '/login',
//               state: { from: location },
//             }}
//           />
//         )
//       }
//     />
//   )
// }


function FilledRoute({ children, isLeague, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLeague ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

const AppNavigator = ({ auth, profile, league }) => {
  // const isLoggedIn = !isEmpty(auth)
  const isLeague = !(typeof league === 'undefined' || league === null)
  const authLoaded = isLoaded(auth)
  if (!authLoaded) {
    return (
      <Box
        sx={{
          width: '100vw',
          minHeight: '100vh',
          height: '100%',
          bgcolor: 'background.default',
        }}
      >
        <Container
          sx={{
            bgcolor: 'background.default',
            color: 'text.primary',
          }}
        >
          <Navigation />
          <Grid
            container
            spacing={0}
            direction='column'
            alignItems='center'
            justifyContent='center'
            style={{ minHeight: '100vh' }}
          >
            <Grid item xs={3}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Container>
      </Box>
    )
  } else {
    // return <div style={{ flex: 1 }}>Loaded</div>
    return (
      <Switch>
        <FilledRoute isLeague={isLeague} path='/fixtures'>
          <AllFixturesPage />
        </FilledRoute>
        <FilledRoute isLeague={isLeague} path='/standings'>
          <StandingsPage />
        </FilledRoute>
        <FilledRoute isLeague={isLeague} path='/teams/:teamid'>
          <TeamPage />
        </FilledRoute>
        <FilledRoute isLeague={isLeague} path='/teams'>
          <TeamsPage />
        </FilledRoute>
        <FilledRoute isLeague={isLeague} path='/reserves'>
          <ReservesPage />
        </FilledRoute>
        <FilledRoute isLeague={isLeague} path='/shirt/:teamid'>
          <ShirtPage />
        </FilledRoute>
        <Route path='/'>
          <FixturesPage />
        </Route>
      </Switch>
    )
  }
}

export default enhance(AppNavigator)
