import React, { useState, useEffect } from 'react'
import Navigation from './Navigation'
import { ReactComponent as SlimfitWhiteShirtBackground } from './assets/slim-fit-white.svg'
import { ReactComponent as SlimfitOrangeShirtBackground } from './assets/slim-fit-orange.svg'
import { ReactComponent as OrangeShirtBackground } from './assets/a54d00.svg'
import { ReactComponent as WhiteShirtBackground } from './assets/FFFFFF.svg'
import { ReactComponent as LahiliigaLogo } from './assets/lahiliiga.svg'
import { TeamColors } from '@cintoiacom/team-colors'
import {
  Container,
  Box,
  Card,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  CardHeader,
  Avatar,
  CardContent,
  CardActions,
  Button,
  Grid
} from '@mui/material'
import Brightness1Icon from '@mui/icons-material/Brightness1'
import BurstModeIcon from '@mui/icons-material/BurstMode'
import PhotoIcon from '@mui/icons-material/Photo'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import { connect } from 'react-redux'
import { firebaseConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import { useParams } from 'react-router-dom'
import withAppconfig from './AppContext'

const TOP_MARGIN = 90

const largeTextTemplates = {
  cursive: {
    width: 400,
    position: 'absolute',
    top: 320 + TOP_MARGIN,
    left: 200,
    fontFamily: 'Mr Dafoe',
    margin: 0,
    fontSize: '4em',
    marginTop: '-0.6em',
    color: 'white',
    textShadow: '0 0 0.05em #fff, 0 0 0.2em #000, 0 0 0.3em #000',
    transform: 'rotate(-7deg)',
    textAlign: 'center',
  },
}

const textLocationTemplates = {
  top: {
    position: 'absolute',
    top: 115 + TOP_MARGIN,
    left: 450,
    fontFamily: 'Saira Semi Condensed',
    color: 'black',
    maxWidth: 100,
    width: 100,
    fontSize: 18,
    textAlign: 'center',
    bgcolor: 'yellow',
    fontWeight: 'bold',
    // textShadow: '0 0 0.05em #fff, 0 0 0.2em #fff, 0 0 0.3em #fff',
  },
  middle: {
    position: 'absolute',
    top: 170 + TOP_MARGIN,
    left: 450,
    fontFamily: 'Saira Semi Condensed',
    color: 'black',
    maxWidth: 100,
    width: 100,
    fontSize: 18,
    textAlign: 'center',
    bgcolor: 'yellow',
    fontWeight: 'bold',
    textShadow: '0 0 0.05em #fff, 0 0 0.2em #fff, 0 0 0.3em #fff',
    transform: 'translate(0, 50%)',
  },
  bottom: {
    position: 'absolute',
    top: 260 + TOP_MARGIN,
    left: 450,
    fontFamily: 'Saira Semi Condensed',
    color: 'black',
    maxHeight: 100,
    height: 100,
    maxWidth: 100,
    width: 100,
    fontSize: 18,
    textAlign: 'center',
    bgcolor: 'yellow',
    fontWeight: 'bold',
    // textShadow: '0 0 0.05em #fff, 0 0 0.2em #fff, 0 0 0.3em #fff',
  },
}

function ShirtPage({ teams }) {
  const [cartItems, setCartItems] = useState([])
  const [textLocation, setTextLocation] = useState('middle')
  const [logoBorderColor, setLogoBorderColor] = useState(1)
  const [shirtColor, setShirtColor] = useState('white')
  const [shirtModel, setShirtModel] = useState('slim')
  const [largeText, setLargeText] = useState('cursive')
  const [team, setTeam] = useState(null)
  let { teamid } = useParams()

  useEffect(() => {
    const _team = teams.find((t) => t.key === teamid)
    if (_team) setTeam(_team)
  }, [teamid, setTeam, teams])

  const handleTextLocationChange = (event, nextView) => {
    setTextLocation(nextView)
  }

  const handleLogoBorderChange = (event, nextView) => {
    setLogoBorderColor(nextView)
  }

  const handleShirtColorChange = (event, nextView) => {
    setShirtColor(nextView)
  }

  const handleShirtModeChange = (event, nextView) => {
    setShirtModel(nextView)
  }

  const handleLargeTextChange = (event, nextView) => {
    setLargeText(nextView)
  }

  const handleCartItemAdd = (item) => {
    setCartItems(cartItems => [...cartItems, item])
  }

  const handleCartItemAmount = (index, change) => {
    cartItems[index].amount = cartItems[index].amount + change
    setCartItems(cartItems.filter((ci) => ci.amount > 0))
  }


  if (isLoaded(teams) && team) {
    let shirtTemplate = <WhiteShirtBackground height={800} />
    if (shirtModel === 'slim-fit') {
      if (shirtColor === 'white') {
        shirtTemplate = <SlimfitWhiteShirtBackground height={800} />
      } else {
        shirtTemplate = <SlimfitOrangeShirtBackground height={800} />
      }
    } else {
      if (shirtColor === 'orange') {
        shirtTemplate = <OrangeShirtBackground height={800} />
      }
    }
    return (
      <Box
        sx={{
          width: '100vw',
          minHeight: '100vh',
          height: '100%',
          bgcolor: 'background.default',
          pb: 10
        }}
      >
        <Container
          sx={{
            bgcolor: 'background.default',
            color: 'text.primary',
            position: 'relative',
          }}
        >
          <Navigation />
          <Grid container>
            <Grid item xs={8}>
              <div style={{ position: 'absolute' }}>{shirtTemplate}</div>
              <div style={{ position: 'absolute', top: 147 + TOP_MARGIN, left: 447 }}>
                <TeamColors
                  height={106}
                  width={106}
                  color1={logoBorderColor}
                  color2={logoBorderColor}
                  color3={logoBorderColor}
                  template={team.value.logo.template}
                  shape={team.value.logo.shape}
                />
              </div>
              <div style={{ position: 'absolute', top: 150 + TOP_MARGIN, left: 450 }}>
                <TeamColors
                  height={100}
                  width={100}
                  color1={team.value.logo.color1}
                  color2={team.value.logo.color2}
                  color3={team.value.logo.color3}
                  template={team.value.logo.template}
                  shape={team.value.logo.shape}
                />
              </div>
              <div style={textLocationTemplates[textLocation]}>
                {textLocation === 'noname' ? null : team?.value?.name}
              </div>
              <div style={{ position: 'absolute', top: 180 + TOP_MARGIN, left: 200 }}>
                <LahiliigaLogo height={70} />
              </div>
              <div style={largeTextTemplates['cursive']}>{largeText === 'noname' ? null : team?.value?.name}</div>
            </Grid>
            <Grid item xs={1}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='caption' display='block' gutterBottom>
                    Tekstin sijainti
                  </Typography>
                  <ToggleButtonGroup
                    orientation='vertical'
                    value={textLocation}
                    exclusive
                    onChange={handleTextLocationChange}
                  >
                    <ToggleButton value='top' aria-label='top'>
                      <BurstModeIcon style={{ transform: 'rotate(90deg)' }} />
                    </ToggleButton>
                    <ToggleButton value='middle' aria-label='middle'>
                      <PhotoIcon />
                    </ToggleButton>
                    <ToggleButton value='bottom' aria-label='bottom'>
                      <BurstModeIcon style={{ transform: 'rotate(-90deg)' }} />
                    </ToggleButton>
                    <ToggleButton value='noname' aria-label='noname'>
                      <DoNotDisturbIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='caption' display='block' gutterBottom>
                    Reunus
                  </Typography>
                  <ToggleButtonGroup
                    orientation='vertical'
                    value={logoBorderColor}
                    exclusive
                    onChange={handleLogoBorderChange}
                  >
                    <ToggleButton value={0} aria-label='list'>
                      <Brightness1Icon style={{ color: 'black' }} />
                    </ToggleButton>
                    <ToggleButton value={1} aria-label='module'>
                      <Brightness1Icon style={{ color: 'white' }} />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='caption' display='block' gutterBottom>
                    Paidan väri
                  </Typography>
                  <ToggleButtonGroup
                    orientation='vertical'
                    value={shirtColor}
                    exclusive
                    onChange={handleShirtColorChange}
                  >
                    <ToggleButton value='white' aria-label='list'>
                      <Brightness1Icon style={{ color: 'white' }} />
                    </ToggleButton>
                    <ToggleButton value='orange' aria-label='module'>
                      <Brightness1Icon style={{ color: 'orange' }} />
                    </ToggleButton>
                  </ToggleButtonGroup>
                  
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='caption' display='block' gutterBottom>
                    Paidan malli
                  </Typography>
                  <ToggleButtonGroup
                    orientation='vertical'
                    value={shirtColor}
                    exclusive
                    onChange={handleShirtModeChange}
                  >
                    <ToggleButton value='slim' aria-label='list'>
                      M
                    </ToggleButton>
                    <ToggleButton value='slim-fit' aria-label='module'>
                      W
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='caption' display='block' gutterBottom>
                    Nimi
                  </Typography>
                  <ToggleButtonGroup
                    orientation='vertical'
                    value={shirtColor}
                    exclusive
                    onChange={handleLargeTextChange}
                  >
                    <ToggleButton value='cursive' aria-label='cursive'>
                      Kursiivi
                    </ToggleButton>
                    <ToggleButton value='noname' aria-label='noname'>
                      <DoNotDisturbIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12}>
                <Button variant="contained" onClick={() => handleCartItemAdd({ name: 'paita', size: 'xl', price: 30, amount: 1 })}>Lisää tilaukseen</Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar>
                      <TeamColors
                        height={35}
                        width={35}
                        color1={team.value.logo.color1}
                        color2={team.value.logo.color2}
                        color3={team.value.logo.color3}
                        template={team.value.logo.template}
                        shape={1}
                      />
                    </Avatar>
                  }
                  title='Paitatilaus'
                />
                <CardContent>
                  {cartItems.map((i, ind) => {
                    return (<Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>{i.name}{i.size}<Button onClick={() => handleCartItemAmount(ind, 1)}>+</Button>{i.amount}<Button onClick={() => handleCartItemAmount(ind, -1)}>-</Button></Typography>)
                  })}
                  <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>{cartItems.reduce((acc, cur) => acc + cur.amount * cur.price, 0)} €</Typography>
                </CardContent>
                <CardActions>
                  <Button size='small' variant="contained">Tilaa</Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    )
  } else {
    return (
      <Box
        sx={{
          width: '100vw',
          minHeight: '100vh',
          height: '100%',
          bgcolor: 'background.default',
        }}
      >
        <Container
          sx={{
            bgcolor: 'background.default',
            color: 'text.primary',
            position: 'relative',
          }}
        >
          <CircularProgress />
        </Container>
      </Box>
    )
  }
}

const withTeams = compose(
  firebaseConnect((props) => [
    {
      path: 'customers/' + props.appconfig.customerid + '/tournaments/teams',
    },
  ]),
  connect((state, props) => {
    if (state.firebase.ordered.customers?.lahiliiga?.tournaments?.teams) {
      return { teams: state.firebase.ordered.customers?.lahiliiga?.tournaments?.teams }
    } else {
      return {
        teams: [],
      }
    }
  })
)

export default withAppconfig(withTeams(ShirtPage))
