import * as React from 'react'
// import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
// import { styled } from '@mui/material/styles'
import Navigation from './Navigation'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
// import Accordion from '@mui/material/Accordion'
// import AccordionSummary from '@mui/material/AccordionSummary'
// import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
// import Stack from '@mui/material/Stack'
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
// import config, { seasons, genders, sports, divisions } from './config'
import { connect } from 'react-redux'
import { firebaseConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { CircularProgress } from '@mui/material'
import withAppconfig from './AppContext'
// import Slide from '@mui/material/Slide'
// import _ from 'underscore'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { filterLeagueTeam } from './StateFilters'

const RADIOS = [
  { value: 'open', label: 'Avoin' },
  { value: 'men', label: 'Miehet' },
  { value: 'women', label: 'Naiset' },
  { value: 'mixed', label: 'Mixty' },
]

const filterAlreadyReserved = (player, genderFilter) => {
  // console.log('filterAlreadyReserved', player, genderFilter)
  if (genderFilter === '') return false
  // if (genderFilter === 'open') return true
  if (genderFilter === 'men' || genderFilter === 'women') {
    if (player.value.gender !== genderFilter) return false
  }
  if (!player.teams) return true
  const g = player.teams.find((t) => t.gender === genderFilter)
  if (!g) return true
  return false
}

function ReservesPage({ dispatch, players, league, leagues, appconfig, uniquegenders }) {
  const isLeague = !(typeof league === 'undefined' || league === null)
  const [gender, setGender] = React.useState('')

  if (!isLeague) {
    return (
      <Box
        sx={{
          width: '100vw',
          minHeight: '100vh',
          height: '100%',
          bgcolor: 'background.default',
          pb: 10,
          paddingLeft: 'env(safe-area-inset-left)',
          paddingRight: 'env(safe-area-inset-right)',
        }}
      >
        <Container
          sx={{
            bgcolor: 'background.default',
            color: 'text.primary',
          }}
        >
          <Navigation />
          <Grid container spacing={2} sx={{ textAlign: 'center', mt: 2 }}>
            <Grid item xs={12}>
              <Typography>Sarjat</Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    )
  } else if (isLoaded(players)) {
    return (
      <Box
        sx={{
          width: '100vw',
          minHeight: '100vh',
          height: '100%',
          bgcolor: 'background.default',
          pb: 10,
        }}
      >
        <Container
          sx={{
            bgcolor: 'background.default',
            color: 'text.primary',
          }}
        >
          <Navigation />
          <Grid container spacing={0} direction='column' justifyContent='flex-start' alignItems='center' xs={12}>
            <Grid item >
              <Typography variant='h6'>Pelaajat ({players?.length})</Typography>
            </Grid>
            <Grid item>
              <FormControl>
                <FormLabel id='demo-radio-buttons-group-label'>
                  Hae kuka ei ole pelannut sarjaa, mutta on pelannut kaudella toista sarjaa
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby='demo-radio-buttons-group-label'
                  value={gender}
                  name='radio-buttons-group'
                  onChange={(e) => {
                    setGender(e.target.value)
                  }}
                >
                  {RADIOS.filter((r) => uniquegenders.includes(r.value)).map((radio) => {
                    return <FormControlLabel value={radio.value} control={<Radio />} label={radio.label} />
                  })}
                </RadioGroup>
              </FormControl>
            </Grid>
          {isLoaded(players) && players && (
              <Grid item>
                <TableContainer>
                  <Table sx={{ minWidth: 512 }} size="small" aria-label="a dense table">
                    <TableBody>
                {/* <Grid container spacing={0} direction='column' justifyContent='flex-start' alignItems='flex-start'> */}
                  {players
                    .filter((p) => filterAlreadyReserved(p, gender))
                    .sort((a, b) => {
                      if (a.value.lastname < b.value.lastname) return -1
                      if (a.value.lastname > b.value.lastname) return 1
                      return 0
                    })
                    .map((player) => {
                      return (
                        <TableRow key={player.id}>
                          <TableCell style={{ borderBottom: 'none' }} >{player.value.lastname + ' ' + player.value.firstname}</TableCell>
                          </TableRow>
                          )
                      // return (
                      //   <Grid item xs key={player.id}>
                      //     <Typography>{player.value.lastname + ' ' + player.value.firstname}</Typography>
                      //   </Grid>
                      // )
                    })}
                      {/* </Grid> */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    )
  } else {
    return (
      <Box
        sx={{
          width: '100vw',
          minHeight: '100vh',
          height: '100%',
          bgcolor: 'background.default',
          pb: 10,
        }}
      >
        <Container
          sx={{
            bgcolor: 'background.default',
            color: 'text.primary',
          }}
        >
          <Navigation />
          <Grid
            container
            spacing={0}
            direction='column'
            alignItems='center'
            justifyContent='center'
            style={{ minHeight: '100vh' }}
          >
            <Grid item xs={3}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Container>
      </Box>
    )
  }
}

const findPlayerTeams = (teams, playerid) => {
  if (teams) {
    return teams.filter((t) => {
      if (t.value && t.value.players && Array.isArray(t.value.players)) {
        return t.value.players.includes(playerid)
      } else {
        return false
      }
    })
  } else {
    return teams
  }
}

const withTeams = compose(
  firebaseConnect((props) => [
    {
      path: 'customers/' + props.appconfig.customerid + '/tournaments/teams',
    },
    {
      path: 'customers/' + props.appconfig.customerid + '/players',
    },
  ]),
  connect((state, props) => {
    if (
      state.firebase.ordered.customers &&
      state.firebase.ordered.customers[props.appconfig.customerid] &&
      state.firebase.ordered.customers[props.appconfig.customerid].players &&
      state.firebase.ordered.customers[props.appconfig.customerid].tournaments &&
      state.firebase.ordered.customers[props.appconfig.customerid].tournaments.teams
    ) {
      const _leagueTeams = state.firebase.ordered.customers[props.appconfig.customerid].tournaments.teams.filter((t) =>
        filterLeagueTeam(t, state)
      )
      const _players = state.firebase.ordered.customers[props.appconfig.customerid].players
        .map((p) => {
          p.teams = findPlayerTeams(_leagueTeams, p.key)
            .map((t) => t.value)
            .map((t) => {
              return { gender: t.gender, name: t.name }
            })
          if (p.teams.length > 0) {
            return p
          } else {
            return null
          }
        })
        .filter((p) => p)

      const _uniquegenders = _leagueTeams.map((t) => t.value.gender).filter((v, i, a) => a.indexOf(v) === i)

      return {
        players: _players,
        update: props.firebase.update,
        push: props.firebase.push,
        dispatch: props.firebase.dispatch,
        remove: props.firebase.remove,
        selections: state.sport,
        league: state.sport?.league?.value,
        leagues: state.firebase.ordered.customers[props.appconfig.customerid].configs.leagues.filter(
          (x) => x.value.state === 'published'
        ),
        uniquegenders: _uniquegenders,
      }
    } else {
      return {
        players: [],
        update: props.firebase.update,
        push: props.firebase.push,
        dispatch: props.firebase.dispatch,
        remove: props.firebase.remove,
        selections: state.sport,
        league: null,
        leagues: [],
        uniquegenders: [],
      }
    }
  })
)

export default withAppconfig(withTeams(ReservesPage))
