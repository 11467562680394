import * as React from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import Navigation from './Navigation'
import { Container } from '@mui/material'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { getTieScores } from './Scores'
import Selector from './components/Selector'
import { TeamColors } from '@cintoiacom/team-colors'
import { filterFixture, filterTeam, filterRegulations } from './StateFilters'
import withAppconfig from './AppContext'
import { useHistory } from 'react-router'
import MuiMarkdown from 'mui-markdown'
import { useMediaQuery } from '@mui/material'
import _ from 'underscore'
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Chip from '@mui/material/Chip'
import { format, parseISO } from 'date-fns'
import { fi } from 'date-fns/locale'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const TieContainer = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}))

function StandingsPage({ table, teams, regulations, fixtures, externals }) {
  const history = useHistory()
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const renderTie = (tie) => {
    console.log('tie', tie)
    //    const location = tie.value.location ? locations.find((l) => l.key === tie.value.location) : null
    let score = null
    if (tie.value.rubbers && tie.value.rubbers.length > 0 && tie.value.rubbers[0].score) {
      const { homepoints, awaypoints } = getTieScores(tie.value.rubbers, tie.value.sport)
      score = (
        <>
          <Typography
            sx={{ mr: 1, ml: 1, color: (theme) => 'success.' + (theme.palette.mode === 'dark' ? 'light' : 'dark') }}
            /*color={'lightgreen'}*/ variant='subtitle1'
            component='span'
          >
            {homepoints} - {awaypoints}
          </Typography>
        </>
      )
    }
    const hometeam = teams.find((t) => t.key === tie.value.home.id)
    const awayteam = teams.find((t) => t.key === tie.value.away.id)
    return (
      <TieContainer key={tie.key}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant='subtitle2' component='span'>
                  {tie.value.home.name}{' '}
                  <TeamColors
                    width={12}
                    height={12}
                    color1={hometeam?.value?.logo?.color1 || 0}
                    color2={hometeam?.value?.logo?.color2 || 0}
                    color3={hometeam?.value?.logo?.color3 || 0}
                    shape={hometeam?.value?.logo?.shape || 1}
                    template={hometeam?.value?.logo?.template || 0}
                  />{' '}
                  -{' '}
                  <TeamColors
                    width={12}
                    height={12}
                    color1={awayteam?.value?.logo?.color1 || 0}
                    color2={awayteam?.value?.logo?.color2 || 0}
                    color3={awayteam?.value?.logo?.color3 || 0}
                    shape={awayteam?.value?.logo?.shape || 1}
                    template={awayteam?.value?.logo?.template || 0}
                  />{' '}
                  {tie.value.away.name} {score}
                </Typography>
              </Grid>
              {!score ? (
                <>
                  <Grid item xs={4} sm={2} md={2}>
                    <Typography variant='body2' component='div'>
                      {tie.value.time
                        ? format(parseISO(tie.value.time), 'eee d.M.yyyy p', {
                            locale: fi,
                          })
                        : null}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={2} md={2}>
                    <Typography variant='body2' component='div'>
                      Kierros {tie.value.round + 1}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} sm={4} md={4}>
                  <Typography variant='body2' component='div' sx={{ color: 'text.secondary' }}>
                    {tie.value.time
                      ? format(parseISO(tie.value.time), 'eee d.M.yyyy', {
                          locale: fi,
                        })
                      : null}{' '}
                    
                  </Typography>
                </Grid>
              )}
              {tie.value.note ? (
                <Grid item xs={12}>
                  <Chip label={tie.value.note} size='small' />
                </Grid>
              ) : null}
            </Grid>
          </AccordionSummary>
          {/* <AccordionDetails>
            <Grid item>
              {tie?.value?.rubbers ? tie.value.rubbers.sort((a, b) => a.order - b.order).map((r) => renderRubberTable(r, hometeam, awayteam)) : null}</Grid>
          </AccordionDetails> */}
        </Accordion>
      </TieContainer>
    )
  }

  const externalFixtures = () => {
    return <React.Fragment>{externals && externals.length > 0 ? externals.map((f) => renderTie(f)) : null}</React.Fragment>
  }

  return (
    <Box
      sx={{
        width: '100vw',
        minHeight: '100vh',
        height: '100%',
        bgcolor: 'background.default',
        pb: 10,
      }}
    >
      <Container
        sx={{
          bgcolor: 'background.default',
          color: 'text.primary',
        }}
      >
        <Navigation />
        <Grid container spacing={2} sx={{ mb: 2, mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Selector />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table sx={{ border: 0 }} size='small' aria-label='a dense table'>
                <TableHead>
                  <TableRow sx={{ td: { border: 0 } }}>
                    <StyledTableCell>Joukkue</StyledTableCell>
                    <StyledTableCell align='center'>{isSmallScreen ? 'O' : 'Ottelut'}</StyledTableCell>
                    <StyledTableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }} align='center'>
                      {isSmallScreen ? 'V' : 'Voitot'}
                    </StyledTableCell>
                    <StyledTableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }} align='center'>
                      {isSmallScreen ? 'T' : 'Tasapelit'}
                    </StyledTableCell>
                    <StyledTableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }} align='center'>
                      {isSmallScreen ? 'H' : 'Häviöt'}
                    </StyledTableCell>
                    <StyledTableCell align='center'>{isSmallScreen ? '+/-' : 'Pelien erotus'}</StyledTableCell>
                    <StyledTableCell align='center'>{isSmallScreen ? 'P' : 'Pisteet'}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {table
                    ? table.map((t, index) => {
                        const team = teams.find((x) => x.key === t.id)
                        return (
                          <TableRow key={index}>
                            <TableCell
                              align='left'
                              style={{
                                cursor: 'pointer',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                padding: '6px 10px',
                              }}
                              onClick={() => history.push('/team/' + t.id)}
                            >
                              {index + 1}.{index < 9 ? <>&nbsp;&nbsp;&nbsp;</> : ' '}
                              <TeamColors
                                width={12}
                                height={12}
                                color1={team?.value?.logo?.color1 || 0}
                                color2={team?.value?.logo?.color2 || 0}
                                color3={team?.value?.logo?.color3 || 0}
                                shape={team?.value?.logo?.shape || 1}
                                template={team?.value?.logo?.template || 0}
                              />
                              &nbsp;&nbsp;{t.name}
                            </TableCell>
                            <TableCell align='center'>{t.ties}</TableCell>
                            <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }} align='center'>
                              {t.wins}
                            </TableCell>
                            <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }} align='center'>
                              {t.draws}
                            </TableCell>
                            <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }} align='center'>
                              {t.lost}
                            </TableCell>
                            <TableCell align='center'>{t.gamediff}</TableCell>
                            <TableCell align='right'>{t.points}</TableCell>
                          </TableRow>
                        )
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} mb={2}>
            {regulations?.value?.description && regulations.value.description !== '' ? (
              <Paper sx={{ padding: 2 }}>
                <MuiMarkdown>{regulations.value.description.replaceAll('_n', '\n\n')}</MuiMarkdown>
              </Paper>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={12}>
            {externalFixtures()}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

const sortTable = (table, fixtures) => {
  const sortedTable = Object.keys(table)
    .map((k) => table[k])
    .sort((a, b) => {
      if (a.points === b.points) {
        return a.gamediff > b.gamediff ? -1 : a.gamediff < b.gamediff ? 1 : 0
      } else {
        return a.points > b.points ? -1 : 1
      }
    })
    .map((x, i) => {
      x.sort = i
      return x
    })
  const rulesSorted = sortedTable.reduce((a, c) => {
    if (!a[c.points]) a[c.points] = []
    a[c.points].push(c)
    return a
  }, {})
  const _points = Object.keys(rulesSorted)
  for (let _p of _points) {
    if (rulesSorted[_p].length === 2) {
      let fixture = fixtures.find(
        (x) =>
          (x.value.home.id === rulesSorted[_p][0].id || x.value.away.id === rulesSorted[_p][0].id) &&
          (x.value.home.id === rulesSorted[_p][1].id || x.value.away.id === rulesSorted[_p][1].id)
      )
      if (fixture) {
        const diffs = getTieScores(fixture.value.rubbers, fixture.value.sport)
        const homehigher = fixture.value.home.id === rulesSorted[_p][0].id
        // swap
        let homesort = rulesSorted[_p][0].sort
        let awaysort = rulesSorted[_p][1].sort

        if (!homehigher) {
          // swap
          homesort = rulesSorted[_p][1].sort
          awaysort = rulesSorted[_p][0].sort
        }
        let swap = false
        if (homehigher && diffs.homepoints < diffs.awaypoints) {
          swap = true
        } else if (!homehigher && diffs.homepoints > diffs.awaypoints) {
          swap = true
        } else if (homehigher && diffs.homegames < diffs.awaygames) {
          swap = true
        } else if (!homehigher && diffs.homegames > diffs.awaygames) {
          swap = true
        }
        if (swap) {
          sortedTable[homesort].sort = awaysort
          sortedTable[awaysort].sort = homesort
        }
      }
    }
  }
  return _.sortBy(sortedTable, 'sort')
}

const countTable = (fixtures) => {
  const table = fixtures.reduce((acc, curr) => {
    if (curr.value.home.id === '' || curr.value.away.id === '') return acc
    if (!acc[curr.value.home.id])
      acc[curr.value.home.id] = {
        id: curr.value.home.id,
        name: curr.value.home.name,
        wins: 0,
        ties: 0,
        draws: 0,
        lost: 0,
        gamediff: 0,
        points: 0,
      }
    if (!acc[curr.value.away.id])
      acc[curr.value.away.id] = {
        id: curr.value.away.id,
        name: curr.value.away.name,
        wins: 0,
        ties: 0,
        draws: 0,
        lost: 0,
        gamediff: 0,
        points: 0,
      }
    if (curr.value.rubbers) {
      const diffs = getTieScores(curr.value.rubbers, curr.value.sport)
      if (diffs.homepoints > diffs.awaypoints) {
        acc[curr.value.home.id].wins++
        acc[curr.value.away.id].lost++
      } else if (diffs.homepoints < diffs.awaypoints) {
        acc[curr.value.away.id].wins++
        acc[curr.value.home.id].lost++
      } else if (diffs.homepoints > 0 && diffs.awaypoints > 0) {
        acc[curr.value.away.id].draws++
        acc[curr.value.home.id].draws++
      }
      acc[curr.value.home.id].points = acc[curr.value.home.id].points + diffs.homepoints
      acc[curr.value.away.id].points = acc[curr.value.away.id].points + diffs.awaypoints

      acc[curr.value.home.id].gamediff = acc[curr.value.home.id].gamediff + (diffs.homegames - diffs.awaygames)
      acc[curr.value.away.id].gamediff = acc[curr.value.away.id].gamediff + (diffs.awaygames - diffs.homegames)

      acc[curr.value.home.id].ties =
        acc[curr.value.home.id].wins + acc[curr.value.home.id].lost + acc[curr.value.home.id].draws
      acc[curr.value.away.id].ties =
        acc[curr.value.away.id].wins + acc[curr.value.away.id].lost + acc[curr.value.away.id].draws
    }
    return acc
  }, {})
  return table
}

const withTeams = compose(
  firebaseConnect((props) => [
    {
      path: 'customers/' + props.appconfig.customerid + '/tournaments/teams',
    },
    {
      path: 'customers/' + props.appconfig.customerid + '/tournaments/fixtures',
    },
    {
      path: 'customers/' + props.appconfig.customerid + '/tournaments/regulations',
    },
  ]),
  connect((state, props) => {
    if (
      state.firebase.ordered.customers &&
      state.firebase.ordered.customers[props.appconfig.customerid] &&
      state.firebase.ordered.customers[props.appconfig.customerid].tournaments &&
      state.firebase.ordered.customers[props.appconfig.customerid].tournaments.teams &&
      state.firebase.ordered.customers[props.appconfig.customerid].tournaments.fixtures &&
      state.firebase.ordered.customers[props.appconfig.customerid].tournaments.regulations &&
      state.sport.sport &&
      state.sport.gender &&
      // state.sport.season &&
      state.sport.division
    ) {
      const _teams = state.firebase.ordered.customers[props.appconfig.customerid].tournaments.teams.filter((t) =>
        filterTeam(t, state)
      )
      const groupfixtures = state.firebase.ordered.customers[props.appconfig.customerid].tournaments.fixtures
        .filter((t) => filterFixture(t, state))
      const _fixtures = groupfixtures.filter((x) => !x.value.donotcount)
      const externals = groupfixtures.filter((x) => x.value.donotcount)

      const _regulations = state.firebase.ordered.customers[props.appconfig.customerid].tournaments.regulations.find(
        (t) => filterRegulations(t, state)
      )

      // const fixturesByTeams = _fixtures.reduce((acc, curr) => {
      //   // console.log(curr, acc)
      //   if (!acc[curr.value.home.id]) acc[curr.value.home.id] = []
      //   if (!acc[curr.value.away.id]) acc[curr.value.away.id] = []
      //   acc[curr.value.home.id].push(curr)
      //   acc[curr.value.away.id].push(curr)
      //   return acc
      // }, {})

      const table = countTable(_fixtures)
      const _table = sortTable(table, _fixtures)

      return {
        table: _table,
        teams: _teams,
        regulations: _regulations,
        fixtures: _fixtures,
        externals

      }
    } else {
      return {
        table: [],
        teams: [],
        regulations: [],
        fixtures: [],
        externals: [],
      }
    }
  })
)

export default withAppconfig(withTeams(StandingsPage))
